.vitruvian{
    opacity: 0.5;
    position: absolute;
    height: 100%;
    right: 0;
  }

.bg-vhms{
  background: -webkit-linear-gradient(180deg,#3949ab,#2c94ce) !important;
  background: linear-gradient(180deg,#3949ab,#2c94ce) !important;
}

